/**
 * 公共头部组件
 * @type {{data(): {}}}
 */
import Cookies from 'js-cookie'
import {mapState} from "vuex";
const Header = {
    name:'Header',
    computed: {
        ...mapState({
            userName: state => state.userCenter.userName,  //获取状态管理用户名称
        }),
    },
    data(){
        return {
            headerUser:Cookies.get('token')
        }
    },
    methods:{
        /**
         * 跳转目录页
         */
        onLink() {
            this.$router.push('/catalogue')
        },
        /**
         * 退出登录
         */
        onLogout() {
            let confirm = window.confirm('您确定要退出系统吗？')
            if(confirm){
                Cookies.remove('token');
                this.$router.push('/login').then()
            }
        },
    }
}
export default Header
