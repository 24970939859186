<template>
    <header class="header-body">
        <!--s: logo-->
        <div class="logo">
            <a href="javascript:" @click="onLink"><img src="../../assets/logo.svg" alt="logo"></a>
        </div>
        <!--e: logo-->

        <!--s: User center-->
        <div class="header-user" v-if="headerUser">
            <span class="user-name">{{ userName }}</span>
            <a href="javascript:" class="user-logout" @click="onLogout">Logout</a>
        </div>
        <!--e: User center-->
    </header>
</template>
<script>
import Header from './header'
export default Header
</script>
<style lang="less">
@import "header";
</style>
