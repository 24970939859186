const defaultConfig = {
    env: 'development', // 本地开发环境
    // 接口域名配置
    domain: {
        httpUrl:'http://47.95.150.88:8000', //登录方面domain
        autoHttpUrl:'http://47.95.150.88:8000' //登录成功后 domain
    },
};
const config = {
    testing:{
        env: 'testing',  //测试环境
        // 域名配置
        domain: {
            httpUrl:'http://47.95.150.88:8000', //登录方面domain
            autoHttpUrl:'http://47.95.150.88:8000' //登录成功后 domain
        }
    },
    production:{
        env: 'production', //生产环境
        domain:{
            httpUrl:'http://47.95.150.88:8000', //登录方面domain
            autoHttpUrl:'http://47.95.150.88:8000' //登录成功后 domain
        }
    }
}
// 根据环境变量 配置环境参数, 默认是本地开发环境
const env = process.env.NODE_ENV;
let envConfig = defaultConfig;
switch (env) {
    case 'testing':  //测试环境
        envConfig = config.testing;
        break;
    case 'production':  //生产环境
        envConfig = config.production;
        break;
}
export const  appConfig = envConfig




